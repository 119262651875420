$(document).ready(function () {
  $(function () {
    $('[data-toggle="popover"]').popover({
      html: true,
      container: 'body',
      sanitize: false
    });
  
    $('.popover-dismiss').popover({
      trigger: 'focus',
    });
  })
})