$(document).ready(function () {
  $('.tab-pane').each(function () {
    const $timeElement = $(this).find('.timer');
    let elapsedTime = parseFloat($timeElement.data('time'));
    let status = $timeElement.data('status');
    let intervalId;

    function updateTimer() {
      elapsedTime += 1;
      const minutes = Math.floor(elapsedTime / 60);
      const seconds = Math.floor(elapsedTime % 60);
      const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      $timeElement.text(formattedTime);
    }

    function setFormattedTime() {
      const minutes = Math.floor(elapsedTime / 60);
      const seconds = Math.floor(elapsedTime % 60);
      const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      $timeElement.text(formattedTime);
      return formattedTime;
    }
  
    if ([0, 2].includes(status)) {
      setFormattedTime();
    }

    if (status === 1) {
      updateTimer();
      intervalId = setInterval(updateTimer, 1000);
    }

    $(this).data('intervalId', intervalId);
  });
});
