// app/assets/javascripts/modals.js

$(document).on("turbolinks:load", function () {
  const modal_holder_selector = "#modal-holder";
  const modal_selector = ".modal";

  $(document).on("click", "a[data-modal]", function () {
    const location = $(this).attr("href");
    // Load modal dialog from server
    $.get(location, (data) => {
      $(modal_holder_selector).html(data).find(modal_selector).modal();
      $(".select2").select2({
        theme: "bootstrap4",
      });
      // datepicker
      flatpickr(".datepicker", {
        altInput: true,
        dateFormat: "m/d/Y",
      });
      flatpickr(".datetime_picker", {
        altInput: true,
        enableTime: true,
        allowInput: false,
        dateFormat: "m/d/Y H:i",
      });

      flatpickr(".time_picker", {
        enableTime: true,
        noCalendar: true,
        altInput: true,
        dateFormat: "m/d/Y H:i",
      });
      // dynamic dropdowns
      var $diocese = $(".diocese-select").select2({ theme: "bootstrap4" });
      var $testing_center = $(".testing-center-select").select2({
        theme: "bootstrap4",
      });
      var $testing_window = $(".testing-window-select").select2({
        theme: "bootstrap4",
      });
      var $testing_center_testing_window = $(
        ".testing-center-testing-window-select"
      ).select2({ theme: "bootstrap4" });
      var $testing_center_testing_window_assessment = $(
        ".testing-center-testing-window-assessment-select"
      ).select2({ theme: "bootstrap4" });
      var $testing_center_testing_window_assessment_with_grade = $(
        ".testing-center-testing-window-assessment-with-grade-select"
      ).select2({ theme: "bootstrap4" });
      var $assessment_window = $(".assessment-window-select").select2({
        theme: "bootstrap4",
      })
      $(".students-s").select2({
        theme: "bootstrap4",
      });
      $diocese.on("select2:select", function (e) {
        $.ajax({
          method: "GET",
          data: { diocese: $(this).val() },
          url: "/admin/centers_list",
        }).done(function (resp) {
          $(".testing-center-select").empty().trigger("change");
          // create the option and append to Select2
          $.each(resp, function (i, value) {
            console.log(value);
            var option = new Option(value.text, value.id, true, true);
            $testing_center.append(option);
          });
          $(".testing-center-select").trigger("change");
          $(".testing-center-select").val(null).trigger("change");
        });
      });
      $diocese.on("select2:select", function (e) {
        $.ajax({
          method: "GET",
          data: { diocese: $(this).val() },
          url: "/admin/diocese_testing_windows",
        }).done(function (resp) {
          $(".testing-window-select").empty().trigger("change");
          // create the option and append to Select2
          $.each(resp, function (i, value) {
            console.log(value);
            var option = new Option(value.text, value.id, true, true);
            $testing_window.append(option);
          });
          $(".testing-window-select").trigger("change");
          $(".testing-window-select").val(null).trigger("change");
        });
      });
      $testing_center.on("select2:select", function (e) {
        $.ajax({
          method: "GET",
          data: { testing_center: $(this).val() },
          url: "/admin/testing_center_testing_windows",
        }).done(function (resp) {
          $(".testing-center-testing-window-select").empty().trigger("change");
          // create the option and append to Select2
          $.each(resp, function (i, value) {
            console.log(value);
            var option = new Option(value.text, value.id, true, true);
            $testing_center_testing_window.append(option);
          });
          $(".testing-center-testing-window-select").trigger("change");
          $(".testing-center-testing-window-select")
            .val(null)
            .trigger("change");
        });
      });
      $testing_center.on("select2:select", function (e) {
        $.ajax({
          method: 'GET',
          data: { testing_center: $(this).val() },
          url: '/admin/testing_center_subject_areas',
        }).done(function (resp) {
          $('.school_class_subject_area_ids').empty();
          $('.testing_section_subject_area_ids').empty();
          $('.school_class_subject_area_ids').append(
            '<label class="subject-areas-label">Subject Areas *</label>'
          );
          $('.testing_section_subject_area_ids').append(
            '<label class="subject-areas-label">Subject Areas *</label>'
          );
          $.each(resp, function (i, value) {
            $('.school_class_subject_area_ids').append(
              `<label class="checkbox" style="display: block;">
                <input type="checkbox" name="school_class[subject_area_ids][]" value="${value.id}">
                ${value.name}
              </label>`
            );
            $('.testing_section_subject_area_ids').append(
              `<label class="checkbox" style="display: block;">
                <input type="checkbox" name="school_class[subject_area_ids][]" value="${value.id}">
                ${value.name}
              </label>`
            );
          });
        });
      });
      $(".students-s, .testing-center-select").on("change", function () {
        $.ajax({
          method: "GET",
          data: { testing_center: $(this).val() },
          url: "/admin/testing_center_assessment",
        }).done(function (resp) {
          $(".testing-center-assessment").empty().trigger("change");
          // create the option and append to Select2
          $.each(resp, function (i, value) {
            var option = new Option(value.text, value.id, true, true);
            $(".testing-center-assessment").append(option);
          });
          $(".testing-center-assessment").trigger("change");
          $(".testing-center-assessment").val(null).trigger("change");
        });
      });
      $(".testing-window-select").on("change", function () {
        $.ajax({
          method: "GET",
          data: { testing_window: $(this).val() },
          url: "/admin/testing_window_assessments",
        }).done(function (resp) {
          $(".testing-center-assessment").empty().trigger("change");
          $(".testing-section-assessment").empty().trigger("change");
          // create the option and append to Select2
          $.each(resp, function (i, value) {
            var option = new Option(value.text, value.id, true, true);
            $(".testing-center-assessment").append(option);
            $(".testing-section-assessment").append(option);
          });
          $(".testing-center-assessment").trigger("change");
          $(".testing-section-assessment").trigger("change");
          $(".testing-center-assessment").val(null).trigger("change");
          $(".testing-section-assessment").val(null).trigger("change");
        });
      });

      $(".testing-center-testing-window-select").on("change", function () {
        console.log($(this)[0].getAttribute("grade-level"));
        console.log($(this)[0]);
        $.ajax({
          method: "GET",
          data: { testing_center_testing_window: $(this).val() },
          url:
            "/admin/testing_center_testing_window_assessments?grade_level=" +
            $(this)[0].getAttribute("grade-level"),
        }).done(function (resp) {
          $testing_center_testing_window_assessment.empty().trigger("change");
          // create the option and append to Select2
          $.each(resp, function (i, value) {
            var option = new Option(value.text, value.id, true, true);
            $testing_center_testing_window_assessment.append(option);
          });
          $("testing-center-testing-window-assessment-select").trigger(
            "change"
          );
        });
      });

      $(".testing-center-testing-window-with-grade-select").on(
        "change",
        function () {
          $.ajax({
            method: "GET",
            data: { testing_center_testing_window: $(this).val() },
            url:
              "/admin/testing_center_testing_window_assessments_with_grade?grade_level=" +
              $(this)[0].getAttribute("grade-level"),
          }).done(function (resp) {
            $testing_center_testing_window_assessment_with_grade
              .empty()
              .trigger("change");
            // create the option and append to Select2
            $.each(resp, function (i, value) {
              var option = new Option(value.text, value.id, true, true);
              $testing_center_testing_window_assessment_with_grade.append(
                option
              );
            });
            $(
              "testing-center-testing-window-assessment-with-grade-select"
            ).trigger("change");
          });
        }
      );

      $(".publisher-select").on("change", function () {
        $.ajax({
          method: "GET",
          data: { publisher: $(this).val() },
          url: "/admin/publisher_material",
        }).done(function (resp) {
          $(".material-select").empty().trigger("change");
          // create the option and append to Select2
          $.each(resp, function (i, value) {
            var option = new Option(value.text, value.id, true, true);
            $(".material-select").append(option);
          });
          $(".material-select").trigger("change");
          $(".material-select").val(null).trigger("change");
        });
      });

      $(".second-publisher-select").on("change", function () {
        $.ajax({
          method: "GET",
          data: { publisher: $(this).val() },
          url: "/admin/publisher_material",
        }).done(function (resp) {
          $(".second-material-select").empty().trigger("change");
          // create the option and append to Select2
          $.each(resp, function (i, value) {
            var option = new Option(value.text, value.id, true, true);
            $(".second-material-select").append(option);
          });
          $(".second-material-select").trigger("change");
          $(".second-material-select").val(null).trigger("change");
        });
      });

      $(".material-select").on("change", function () {
        $.ajax({
          method: "GET",
          data: { material: $(this).val() },
          url: "/admin/material_edition",
        }).done(function (resp) {
          $(".edition-select").empty().trigger("change");
          // create the option and append to Select2
          $.each(resp, function (i, value) {
            var option = new Option(value.text, value.id, true, true);
            $(".edition-select").append(option);
          });
          $(".edition-select").trigger("change");
          $(".edition-select").val(null).trigger("change");
        });
      });

      $(".second-material-select").on("change", function () {
        $.ajax({
          method: "GET",
          data: { material: $(this).val() },
          url: "/admin/material_edition",
        }).done(function (resp) {
          $(".second-edition-select").empty().trigger("change");
          // create the option and append to Select2
          $.each(resp, function (i, value) {
            var option = new Option(value.text, value.id, true, true);
            $(".second-edition-select").append(option);
          });
          $(".second-edition-select").trigger("change");
          $(".second-edition-select").val(null).trigger("change");
        });
      });

      // select on create class page for teachers
      $(".testing-center-select").on("change", function () {
        $.ajax({
          method: "GET",
          data: { testing_center: $(this).val() },
          url: "/admin/teachers_by_testing_center",
        }).done(function (resp) {
          $(".teachers_filter").empty().trigger("change");
          // create the option and append to Select2
          $.each(resp, function (i, value) {
            var option = new Option(value.text, value.id, true, true);
            $(".teachers_filter").append(option);
          });
          $(".teachers_filter").trigger("change");
          $(".teachers_filter").val(null).trigger("change");
        });
      });
      $(".testing-center-select").on("change", function () {
        $.ajax({
          method: "GET",
          data: { testing_center: $(this).val() },
          url: "/admin/proctors_by_testing_center",
        }).done(function (resp) {
          $(".proctors_filter").empty().trigger("change");
          // create the option and append to Select2
          $.each(resp, function (i, value) {
            var option = new Option(value.text, value.id, true, true);
            $(".proctors_filter").append(option);
          });
          $(".proctors_filter").trigger("change");
          $(".proctors_filter").val(null).trigger("change");
        });
      });

      $(".testing-center-select").on("change", function () {
        $.ajax({
          method: "GET",
          data: { testing_center: $(this).val() },
          url: "/admin/testing_center_allow_pre_test",
        }).done(function (resp) {
          if (resp == true) {
            $("#testing_section_pre_test")
              .removeClass("disabled")
              .removeAttr("disabled");
            $("#testing_section_pre_test + label").removeClass("disabled");
            $(".testing_section_pre_test").removeClass("disabled");
          } else {
            $("#testing_section_pre_test")
              .addClass("disabled")
              .prop("checked", false)
              .attr("disabled", "disabled");
            $("#testing_section_pre_test + label").addClass("disabled");
            $(".testing_section_pre_test").addClass("disabled");
          }
        });
      });

      $(".testing-center-select").on("change", function () {
        $.ajax({
          method: "GET",
          data: { testing_center: $(this).val() },
          url: "/admin/grade_levels_by_testing_center",
        }).done(function (resp) {
          $(".grade-level-select").empty().trigger("change");
          // create the option and append to Select2
          $.each(resp, function (i, value) {
            var option = new Option(value.text, value.id, true, true);
            $(".grade-level-select").append(option);
          });
          $(".grade-level-select").trigger("change");
          $(".grade-level-select").val(null).trigger("change");
        });
      });

      $(".testing-center-select").on("change", function () {
        $.get("/admin/testing_center_current_year", {
          testing_center: $(this).val(),
        }).done(function (res) {
          $("#current_year").html(res);
        });
      });

      $assessment_window.on("select2:select", function (e) {
        $.ajax({
          method: "GET",
          data: { assessment_window: $(this).val() },
          url: "/admin/assessment_window_dates",
        }).done(function (resp) {
          start_date = new Date(resp.start_date);
          start_date.setDate(start_date.getDate() + 1);

          end_date = new Date(resp.end_date);
          end_date.setDate(end_date.getDate() + 1);

          const $fp_start_date = flatpickr("#start-date", {
            dateFormat: "d-M-Y",
            minDate: start_date,
            maxDate: end_date
          });
          const $fp_end_date = flatpickr("#end-date", {
            dateFormat: "d-M-Y",
            minDate: start_date,
            maxDate: end_date
          });

          $fp_start_date.setDate(start_date);
          $fp_end_date.setDate(end_date);
        });
      });

      $(".validate").validate();
      $("input[type='radio']").on("click", function () {
        var radioValue = $(
          "input[name='assessment[is_teacher]']:checked"
        ).val();
        if (radioValue == "true") {
          $(".assessment_grade_level").addClass("d-none");
        } else if (radioValue == "false") {
          $(".assessment_grade_level").removeClass("d-none");
        }
      });

      var url = "/admin/file_upload";
      const addtionalFile = document.querySelector(".file_upload");
      FilePond.registerPlugin(FilePondPluginFileValidateType);
      const addpond = FilePond.create(addtionalFile, {
        multiple: false,
        allowFileTypeValidation: true,
        acceptedFileTypes: ["text/csv", "application/csv"],
        server: {
          process: (
            fieldName,
            file,
            metadata,
            load,
            error,
            progress,
            abort,
            transfer,
            options
          ) => {
            document.getElementById("upload_error").innerHTML = "";
            const formData = new FormData();
            formData.append("filepond", file, file.name);
            formData.append("importable_type", $(".importable_type").val());
            formData.append(
              "importable_id",
              $("#user_testing_center_id").val()
            );

            const request = new XMLHttpRequest();
            request.open("POST", url);
            request.upload.onprogress = (e) => {
              progress(e.lengthComputable, e.loaded, e.total);
            };
            request.onload = function () {
              if (request.status >= 200 && request.status < 300) {
                $(".import_body").html(request.responseText);
                $(".import_form").validate();
                load(request.responseText);
              } else {
                error("oh no");
                console.log("error");
                document.getElementById("upload_error").innerHTML =
                  request.responseText;
              }
            };

            request.send(formData);

            return {
              abort: () => {
                request.abort();

                abort();
              },
            };
          },
        },
      });
    });

    return false;
  });

  $(document).on("ajax:success", "form[data-modal]", function (event) {
    const [data, _status, xhr] = event.detail;
    const url = xhr.getResponseHeader("Location");
    if (url) {
      // Redirect to url
      window.location = url;
    } else {
      // Remove old modal backdrop
      //$('.modal-backdrop').remove();
      // Update modal content
      console.log(data);
      // const modal = $(data).find('body').html();
      // $(modal_holder_selector).html(modal).find(modal_selector).modal();
      $(".resp").html("");
      $(".resp").html(data.resp);
      $(".simple_form").trigger("reset");
      $('#students-table').replaceWith(data.students_table);
      if (data.type == "students-s") {
        $(".students-s").empty().trigger("change");
        // create the option and append to Select2
        $.each(data.result, function (i, value) {
          var option = new Option(value.name, value.id, true, true);
          $(".students-s").append(option);
        });
      }
      $(".select2").trigger("change");
      $(".select2").val("").trigger("change");
    }

    return false;
  });
  $(document).on("hidden.bs.modal", "#mainModal", function (e) {
    $(".modal-backdrop").remove();
  });
});
